import { Link } from 'gatsby';
import React from 'react';

import ReliefCTAIcon from '../../assets/images/relief-cta-icon.svg';

function AtTheDoctorCTA() {
  return (
    <section className="at-the-doctor-cta bg-purple-100">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between items-start at-the-doctor-cta-content mx-auto">
          <img
            src={ReliefCTAIcon}
            alt="Illustration of female body experiencing cramps"
            width={120}
            height={95}
          />

          <div className="flex flex-col at-the-doctor-cta-title">
            <h2 className="text-white-100">
              Relief from moderate to severe endometriosis pain is possible with
              Myfembree.
            </h2>

            <Link
              className="white-btn block"
              to="/endometriosis/why-myfembree/"
            >
              SEE PROVEN RESULTS
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AtTheDoctorCTA;
